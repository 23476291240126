<template>
  <div class="card card-custom">
    <div class="card-body p-0">
      <!--begin: Wizard-->
      <div
        class="wizard wizard-2"
        id="kt_wizard_destination"
        data-wizard-state="step-first"
        data-wizard-clickable="false"
      >
        <!--begin: Wizard Nav -->
        <div class="wizard-nav border-right py-8 px-8 py-lg-20 px-lg-10">
          <div class="wizard-steps">
            <div class="wizard-step my-1" data-wizard-type="step" :data-wizard-state="step.state" v-for="step in steps" :key="step.title">
              <div class="wizard-wrapper">
                <div class="wizard-label">
                  <h3 class="wizard-title mb-0">
                    {{ step.title }}
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--end: Wizard Nav -->

        <!--begin: Wizard Body -->
        <div class="wizard-body py-8 px-8 py-lg-20 px-lg-10">
          <!--begin: Wizard Form-->
          <div class="row">
            <div class="offset-xxl-2 col-xxl-8">
              <form class="form" id="kt_form">
                <!--begin: Wizard Step 1-->
                <div
                  class="pb-5"
                  data-wizard-type="step-content"
                  data-wizard-state="current"
                >
                  <h1 class="mb-5 font-weight-bolder text-dark display-4">
                    Destinasyonun adı nedir?
                  </h1>
                  <p class="font-size-h5 mb-7">
                    Destinasyonun adını girin.
                  </p>
                  <div class="row">
                    <div class="col-xl-6">
                      <div class="form-group">
                        <label class="text-body font-weight-bold pl-1 d-flex align-items-center">
                          Türkçe
                          <Flag class="ml-2" code="TR" size="S" :hasDropShadow="true" :hasBorder="true" :hasBorderRadius="true"/>
                        </label>
                        <input type="text" placeholder="Adını girin" class="form-control form-control-lg" autocomplete="off" name="destinationNameTR" @blur="$v.destinationNameTR.$touch()" v-model="destinationNameTR">
                        <span class="form-text text-danger" v-if="$v.destinationNameTR.$error">Bu alan boş geçilemez.</span>
                      </div>
                    </div>
                    <div class="col-xl-6">
                      <div class="form-group">
                        <label class="text-body font-weight-bold pl-1 d-flex align-items-center">
                          İngilizce
                          <Flag class="ml-2" code="GB-UKM" size="S" :hasDropShadow="true" :hasBorder="true" :hasBorderRadius="true"/>
                        </label>
                        <input type="text" placeholder="Adını girin" class="form-control form-control-lg" autocomplete="off" name="destinationNameEN" @blur="$v.destinationNameEN.$touch()" v-model="destinationNameEN">
                        <span class="form-text text-danger" v-if="$v.destinationNameEN.$error">Bu alan boş geçilemez.</span>
                      </div>
                    </div>
                  </div>
                </div>
                <!--end: Wizard Step 1-->

                <!--begin: Wizard Step 2-->
                <div
                  class="pb-5"
                  data-wizard-type="step-content"
                >
                  <h1 class="mb-5 font-weight-bolder text-dark display-4">
                    Yatay fotoğraf
                  </h1>
                  <p class="font-size-h5 mb-7">
                    Destinasyonu en iyi ifade anlatan geçerli formatta yatay bir fotoğraf yükleyin.
                  </p>
                  <div class="form-group row">
                    <div class="col-xl-7 col-lg-6">
                      <file-pond
                          :class="{ 'invalid': $v.horizontalImage.$error }"
                          name="horizontal"
                          allow-multiple="false" 
                          accepted-file-types="image/jpeg, image/png, image/jpg"
                          max-files="1" 
                          maxFileSize="500KB"
                          imageValidateSizeMinWidth="1920"
                          imageValidateSizeMaxWidth="1920"
                          imageValidateSizeMinHeight="1080"
                          imageValidateSizeMaxHeight="1080"
                          v-on:removefile="removeFileH"
                          :file-rename-function="renameFile" 
                          :server="{ process }"
                          label-idle='<div style="width:100%;height:100%;">
                            <h3 style="font-size: 16px">
                              Drag &amp; Drop your files or <span class="filepond--label-action" tabindex="0">Browse</span><br>
                            </h3>
                          </div>'
                        />
                        <input type="hidden" name="horizontalImage" v-model="horizontalImage">
                        <span class="form-text text-danger" v-if="$v.horizontalImage.$error">Bu alan boş geçilemez.</span>
                    </div>
                    <div class="col-xl-5 col-lg-6">
                      <div class="d-flex flex-column justify-content-between flex-wrap">
                        <div class="d-flex align-items-center justify-content-end flex-wrap">
                          <div class="symbol symbol-70 flex-shrink-0 mr-4 mb-4">
                            <div class="symbol-label">
                              <span class="text-dark-65 font-weight-bolder">250 KB</span>
                            </div>
                          </div>
                          <div class="symbol symbol-70 flex-shrink-0 mr-4 mb-4">
                            <div class="symbol-label">
                              <span class="text-dark-65 font-weight-bolder">16:9</span>
                            </div>
                          </div>
                          <div class="symbol symbol-70 flex-shrink-0 mr-4 mb-4">
                            <div class="symbol-label">
                              <div class="d-flex flex-column align-items-center">
                                <span class="text-dark-65 font-weight-light">Width</span>
                                <span class="text-dark-65 font-weight-bolder">1920 px</span>
                              </div>
                            </div>
                          </div>
                          <div class="symbol symbol-70 flex-shrink-0 mr-4 mb-4">
                            <div class="symbol-label">
                              <div class="d-flex flex-column align-items-center">
                                <span class="text-dark-65 font-weight-light">Height</span>
                                <span class="text-dark-65 font-weight-bolder">1080 px</span>
                              </div>
                            </div>
                          </div>
                          <div class="symbol symbol-70 flex-shrink-0 mr-4">
                            <div class="symbol-label">
                              <span class="text-dark-65 font-weight-bolder">JPG</span>
                            </div>
                          </div>
                          <div class="symbol symbol-70 flex-shrink-0 mr-4">
                            <div class="symbol-label">
                              <span class="text-dark-65 font-weight-bolder">PNG</span>
                            </div>
                          </div>
                        </div>
                      </div>            
                    </div>
                  </div>
                </div>
                <!--end: Wizard Step 2-->

                <!--begin: Wizard Step 3-->
                <div
                  class="pb-5"
                  data-wizard-type="step-content"
                >
                  <h1 class="mb-5 font-weight-bolder text-dark display-4">
                    Dikey fotoğraf
                  </h1>
                  <p class="font-size-h5 mb-7">
                    Destinasyonu en iyi ifade anlatan geçerli formatta dikey bir fotoğraf yükleyin.
                  </p>
                  <div class="form-group row">
                    <div class="col-xl-7 col-lg-6">
                      <file-pond
                          name="vertical"
                          allow-multiple="false" 
                          accepted-file-types="image/jpeg, image/png, image/jpg"
                          max-files="1" 
                          maxFileSize="500KB"
                          imageValidateSizeMinWidth="1080"
                          imageValidateSizeMaxWidth="1080"
                          imageValidateSizeMinHeight="1920"
                          imageValidateSizeMaxHeight="1920"
                          :file-rename-function="renameFile" 
                          v-on:removefile="removeFileV"
                          :server="{ process }"
                          label-idle='<div style="width:100%;height:100%;">
                            <h3 style="font-size: 16px">
                              Drag &amp; Drop your files or <span class="filepond--label-action" tabindex="0">Browse</span><br>
                            </h3>
                          </div>'
                        />
                        <input type="hidden" name="horizontalImage" v-model="verticalImage">
                        <span class="form-text text-danger" v-if="$v.verticalImage.$error">Bu alan boş geçilemez.</span>
                    </div>
                    <div class="col-xl-5 col-lg-6">
                      <div class="d-flex flex-column justify-content-between flex-wrap">
                        <div class="d-flex align-items-center justify-content-end flex-wrap">
                          <div class="symbol symbol-70 flex-shrink-0 mr-4 mb-4">
                            <div class="symbol-label">
                              <span class="text-dark-65 font-weight-bolder">250 KB</span>
                            </div>
                          </div>
                          <div class="symbol symbol-70 flex-shrink-0 mr-4 mb-4">
                            <div class="symbol-label">
                              <span class="text-dark-65 font-weight-bolder">9:16</span>
                            </div>
                          </div>
                          <div class="symbol symbol-70 flex-shrink-0 mr-4 mb-4">
                            <div class="symbol-label">
                              <div class="d-flex flex-column align-items-center">
                                <span class="text-dark-65 font-weight-light">Width</span>
                                <span class="text-dark-65 font-weight-bolder">1080 px</span>
                              </div>
                            </div>
                          </div>
                          <div class="symbol symbol-70 flex-shrink-0 mr-4 mb-4">
                            <div class="symbol-label">
                              <div class="d-flex flex-column align-items-center">
                                <span class="text-dark-65 font-weight-light">Height</span>
                                <span class="text-dark-65 font-weight-bolder">1920 px</span>
                              </div>
                            </div>
                          </div>
                          <div class="symbol symbol-70 flex-shrink-0 mr-4">
                            <div class="symbol-label">
                              <span class="text-dark-65 font-weight-bolder">JPG</span>
                            </div>
                          </div>
                          <div class="symbol symbol-70 flex-shrink-0 mr-4">
                            <div class="symbol-label">
                              <span class="text-dark-65 font-weight-bolder">PNG</span>
                            </div>
                          </div>
                        </div>
                      </div>            
                    </div>
                  </div>
                </div>
                <!--end: Wizard Step 3-->

                 <!--begin: Wizard Actions -->
                <div class="d-flex justify-content-between border-top pt-10">
                  <div class="mr-2">
                    <button
                      class="btn btn-secondary font-weight-bold px-9 py-4"
                      data-wizard-type="action-prev"
                    >
                      Geri
                    </button>
                  </div>
                  <div>
                    <button
                      v-on:click="submit"
                      class="btn btn-dark font-weight-bold px-9 py-4"
                      ref="kt_create_destination"
                      data-wizard-type="action-submit"
                    >
                      Kaydet
                    </button>
                    <button
                      class="btn btn-dark font-weight-bolder px-9 py-4"
                      data-wizard-type="action-next"
                    >
                      Sonraki Adım
                    </button>
                  </div>
                </div>
                <!--end: Wizard Actions -->

              </form>
            </div>
            <!--end: Wizard-->
          </div>
        </div>
        <!--end: Wizard Body -->
      </div>
      <!--end: Wizard-->
    </div>
  </div>
</template>

<style lang="scss">
@import "@/assets/sass/pages/wizard/wizard-2.scss";

.filepond--wrapper {
  height: 100%;
}
.filepond--panel-root {
  background-color: transparent !important;
  border: 3px dashed #EBEDF3;
}
.filepond--wrapper.invalid .filepond--panel-root {
  border-color: #F64E60 !important;
}
.filepond--drop-label {
  background-color: transparent !important;
  height: 100% !important;
}
.filepond--root.filepond--hopper {
  height: 100% !important;
}
.filepond--root .filepond--drop-label {
  height: 100% !important;
}
</style>

<script>
//import KTUtil from "@/assets/js/components/util";
import ApiService from '@/core/services/api.service';
import KTWizard from "@/assets/js/components/wizard";
import Swal from "sweetalert2";
import JwtService from '@/core/services/jwt.service';
import { validationMixin } from "vuelidate";
import { required} from "vuelidate/lib/validators";

import vueFilePond from 'vue-filepond';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type/dist/filepond-plugin-file-validate-type.esm.js';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size/dist/filepond-plugin-file-validate-size.esm.js';
import FilePondPluginImageValidateSize from 'filepond-plugin-image-validate-size/dist/filepond-plugin-image-validate-size.esm.js';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.esm.js';
import FilePondPluginFileRename from 'filepond-plugin-file-rename/dist/filepond-plugin-file-rename.esm.js';
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
const FilePond = vueFilePond(FilePondPluginFileValidateType, FilePondPluginFileValidateSize, FilePondPluginImageValidateSize, FilePondPluginImagePreview, FilePondPluginFileRename);

export default {
  mixins: [validationMixin],
  name: "CreateDestinationWizard",
  components: {
    FilePond
  },
  data() {
    return {
      steps: [
        {
          title: 'Destinasyonun Adı',
          state: 'current'
        },
        {
          title: 'Yatay Fotoğraf',
          state: 'pending'
        },
        {
          title: 'Dikey Fotoğraf',
          state: 'pending'
        },
      ],

      destinationNameTR: '',
      destinationNameEN: '',
      horizontalImage: '',
      verticalImage: '',
    }
  },
  validations: {
    destinationNameTR: {
      required
    },
    destinationNameEN: {
      required
    },
    horizontalImage: {
      required
    },
    verticalImage: {
      required
    }
  },
  mounted() {
    const self = this;

    // Initialize form wizard
    const wizard = new KTWizard("kt_wizard_destination", {
      startStep: 1, // initial active step number
      clickableSteps: false // allow step clicking
    });

    // Change event
    wizard.on("change", function(wizardObj) {
      switch (wizardObj.getStep()) {
        case 1:
          self.$v.destinationNameTR.$touch();
          self.$v.destinationNameEN.$touch();
          if (self.$v.destinationNameTR.$invalid || self.$v.destinationNameEN.$invalid) {
            wizardObj.stop();
          }
          break;
        case 2:
          if (wizardObj.newStep > wizardObj.currentStep) {
            self.$v.horizontalImage.$touch();
            if (self.$v.horizontalImage.$invalid) {
              wizardObj.stop();
            }
          }
          break;
      }
    });
  },
  methods: {
    process (fieldName, file, metadata, load, error, progress, abort) {
      const formData = new FormData();
      formData.append('image', file, file.name);

      const request = new XMLHttpRequest();
      const req = {
        "horizontal": "https://api.zenofontours.com/admin/v1/destination/uploadHorizontalImage.req.php",
        "vertical": "https://api.zenofontours.com/admin/v1/destination/uploadVerticalImage.req.php"
      }
      request.open("POST", req[fieldName]);
      request.setRequestHeader('Authorization', JwtService.getToken());

      request.upload.onprogress = e => {
        progress(e.lengthComputable, e.loaded, e.total);
      };

      const self = this;
      request.onload = function() {
        if (request.status >= 200 && request.status < 300) {
          if (fieldName === 'horizontal') {
            self.horizontalImage = file.name;
          } else {
            self.verticalImage = file.name;
          }
          load();
        } else {
          // Can call the error method if something is wrong, should exit after
          if (fieldName === 'horizontal') {
            self.horizontalImage = '';
          } else {
            self.verticalImage = '';
          }
          error();
        }
      };

      request.send(formData);

      return {
        abort: () => {
          // This function is entered if the user has tapped the cancel button
          request.abort();

          // Let FilePond know the request has been cancelled
          abort();
        }
      }; 
    },

    renameFile(file) {
      return Date.now() + '_' + file.name;
    },

    removeFileH() {
      this.horizontalImage = '';
    },

    removeFileV() {
      this.verticalImage = '';
    },

    submit: function(e) {
      e.preventDefault();

      this.$v.verticalImage.$touch();
      if (this.$v.verticalImage.$invalid) { return; };

      const submitButton = this.$refs["kt_create_destination"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      const data = {
        countryId: 11,
        name: JSON.stringify({
          tr: this.destinationNameTR,
          en: this.destinationNameEN
        }),
        description: JSON.stringify({
          tr: {
            header: "",
            description: ""
          },
          en: {
            header: "",
            description: ""
          }
        }),
        horizontalImage: this.horizontalImage,
        verticalImage: this.verticalImage
      }
      ApiService.post('destination/create.req.php', data)
      .then(({ data }) => {
        submitButton.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
        );
        this.$router.push({ name: 'show-destination', params: { id: data.destinationId } });
      })
      .catch(({ response }) => {
        submitButton.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
        );
        Swal.fire({
          title: response.data.error,
          text: response.data.errorMessage,
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false
        });
      });
    }
  }
};
</script>
